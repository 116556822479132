var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex",class:{
    'h-screen overflow-hidden sm:overflow-unset': _vm.isBuildMenuOpen,
  },attrs:{"id":"app"}},[_c('div',{staticClass:"container flex flex-col h-full p-4 mx-auto sm:p-8 sm:pt-8"},[_c('PHeader'),_c('div',{staticClass:"space-y-4 transition-transform duration-700 delay-200 sm:space-y-12",style:({
        transform: `translateY(${
          _vm.retailers.length
            ? 'max(100px, min(100px, (50vh - 50% - 58px - 2rem))'
            : 'max(50px, 50vh - 50% - 58px - 2rem)'
        })`,
      })},[_c('PLogo',{staticClass:"mx-auto transform scale-75 sm:scale-100"}),_c('Search')],1)],1),_c('BuildsMenu')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }